<template>
  <div class="Campaign">
    <div class="no-content" v-if="!campaign">
      <h2>Loading...</h2>
    </div>
    <div class="content" v-else>
      <div :style="{ 'background-color': campaign.pageData.bgBanner }">
        <v-img :src="campaign.pageData.bannerUrl" max-width="1200" class="mx-auto"></v-img>
      </div>
      <v-container>
        <v-row id="campaign" class="text-center my-0">
          <v-col cols="12">
            <h2 class="text-h4 primary--text text--darken-3 font-weight-light font-italic py-8">
              CAMPANHA
            </h2>
            <div class="text-left px-4">
              <div class="subtitle-1">
                <span>Cliente</span>:
                <span class="font-weight-bold text-uppercase">{{ campaign.client }}</span>
              </div>
              <div class="subtitle-1">
                <span>Campanha</span>:
                <span class="font-weight-bold text-uppercase">{{ campaign.name }}</span>
              </div>
              <div class="subtitle-1">
                <span>Produto</span>:
                <span class="font-weight-bold text-uppercase">{{ campaign.product }}</span>
              </div>
              <div class="subtitle-1">
                <span>Peça</span>:
                <span class="font-weight-bold text-uppercase">
                  {{ campaign.type }} {{ campaign.format }}
                </span>
              </div>
              <div class="subtitle-1">
                <span>Veiculação</span>:
                <span class="font-weight-bold text-uppercase">{{ campaign.timeFrame }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row id="radios" class="text-center my-0">
          <v-col cols="12">
            <h2 class="text-h4 primary--text text--darken-3 font-weight-light font-italic py-8">
              RÁDIOS SELECIONADAS
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>
              <v-simple-table
                v-if="campaign.id == 'programa-de-testagem-covid-19'"
                dense
                height="100%"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="primary darken-2 text-left white--text">ID. SECOM</th>
                      <th class="primary darken-2 text-left white--text">MUNICÍPIO</th>
                      <th class="primary darken-2 text-left white--text">UF</th>
                      <th class="primary darken-2 text-left white--text">RÁDIO</th>
                      <th class="primary darken-2 text-left white--text">FX</th>
                      <th class="primary darken-2 text-left white--text">PRX</th>
                      <th class="primary darken-2 text-left white--text">PROGRAMA</th>
                      <th class="primary darken-2 text-left white--text">APRESENTADOR</th>
                      <th class="primary darken-2 text-left white--text">INÍCIO</th>
                      <th class="primary darken-2 text-left white--text">FIM</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(radio, i) in campaign.radiosData"
                      :key="i"
                      :class="{ grey: isOdd(i), 'lighten-5': isOdd(i) }"
                    >
                      <td class="text-left">{{ radio.idSecom }}</td>
                      <td class="text-left">{{ radio.municipio }}</td>
                      <td class="text-left">{{ radio.uf }}</td>
                      <td class="text-left">{{ radio.name }}</td>
                      <td class="text-left">{{ radio.fx }}</td>
                      <td class="text-left">{{ radio.prx }}</td>
                      <td class="text-left">{{ radio.programa }}</td>
                      <td class="text-left">{{ radio.apresentador }}</td>
                      <td class="text-left">{{ radio.inicio }}</td>
                      <td class="text-left">{{ radio.fim }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-simple-table
                v-else
                fixed-header
                :dense="isMobile ? true : false"
                :height="
                  isMobile
                    ? campaign.radiosData.length > 10
                      ? 674
                      : '100%'
                    : campaign.radiosData.length > 15
                    ? 530
                    : '100%'
                "
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="primary darken-2 text-left white--text">ID. SECOM</th>
                      <th class="primary darken-2 text-left white--text">MUNICÍPIO</th>
                      <th class="primary darken-2 text-left white--text">UF</th>
                      <th class="primary darken-2 text-left white--text">RÁDIO</th>
                      <th class="primary darken-2 text-left white--text">FX</th>
                      <th class="primary darken-2 text-left white--text">PRX</th>
                      <th class="primary darken-2 text-left white--text">PROGRAMA</th>
                      <th class="primary darken-2 text-left white--text">APRESENTADOR</th>
                      <th class="primary darken-2 text-left white--text">INÍCIO</th>
                      <th class="primary darken-2 text-left white--text">FIM</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(radio, i) in campaign.radiosData"
                      :key="i"
                      :class="{ grey: isOdd(i), 'lighten-5': isOdd(i) }"
                    >
                      <td class="text-left">{{ radio.idSecom }}</td>
                      <td class="text-left">{{ radio.municipio }}</td>
                      <td class="text-left">{{ radio.uf }}</td>
                      <td class="text-left">{{ radio.name }}</td>
                      <td class="text-left">{{ radio.fx }}</td>
                      <td class="text-left">{{ radio.prx }}</td>
                      <td class="text-left">{{ radio.programa }}</td>
                      <td class="text-left">{{ radio.apresentador }}</td>
                      <td class="text-left">{{ radio.inicio }}</td>
                      <td class="text-left">{{ radio.fim }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="campaign">
        <v-row id="content" class="text-center my-0">
          <v-col cols="12">
            <h2 class="text-h4 primary--text text--darken-3 font-weight-light font-italic py-8">
              CONTEÚDO DIVULGADO
            </h2>
            <div class="text-left px-4">
              <p class="font-weight-bold">
                <span>{{ campaign.client }}</span> <br />
                <span>
                  {{ campaign.name }}
                </span>
                <br />
                <span>{{ campaign.type }} {{ campaign.format }}</span> <br />
              </p>
              <div v-if="campaign.id == 'combate-ao-feminicidio'">
                <p>Apresentador do programa fala em tom de conversa, mas bem sério.</p>

                <p><b> Apresentador: </b></p>

                <div style="margin-left: 20px">
                  <p>
                    Muita atenção agora, pessoal, porque o assunto é muito sério. O Distrito Federal
                    tem batido um recorde terrível, que é o de casos de violência contra a mulher e
                    o feminicídio.
                  </p>
                  <p>
                    É um tema que traz muita tristeza para várias famílias. Em vários casos, o crime
                    é cometido pelo atual ou pelo ex-companheiro e muitas vezes as mulheres deixam
                    filhos desamparados.
                  </p>
                  <p>
                    Então o GDF faz um comunicado importante: todo mundo tem que ficar atento. A
                    própria mulher em situação de risco tem vários canais de comunicação e de apoio
                    que o GDF disponibiliza, mas isso também é papel dos parentes, dos amigos, dos
                    vizinhos.
                  </p>
                  <p>
                    Todos nós devemos fazer parte dessa rede de proteção, porque a violência é uma
                    covardia, mas a omissão também é.
                  </p>
                  <p>
                    Então, se você ouvir algum caso de violência contra a mulher, ligue 190 e
                    denuncie.
                  </p>
                  <p>
                    Porque justamente a sua denúncia pode salvar uma vida. Esse é o recado do GDF.
                  </p>
                </div>
              </div>

              <div v-if="campaign.id == 'pacote-institucional-2023'">
                <p><b> LOCUTOR: (fala data e horário) </b></p>

                <div style="margin-left: 20px">
                  <p>
                    Você sabe onde fica a maior obra do GDF? Tem muitas por aí nas ruas... Mas a
                    maior obra mesmo está na mesa das mais de 100 mil famílias que recebem o Cartão
                    Prato Cheio.
                  </p>
                  <p>
                    Está no fogão dos mais de 70 mil beneficiados ganham o Cartão Gás e está nas
                    mochilas dos mais de 113 mil alunos que receberam o benefício do cartão material
                    escolar.
                  </p>
                  <p>
                    E se você acha que acabou, está enganado. A maior obra do GDF também está na
                    tranquilidade de milhares de famílias que têm o cartão creche garantindo a vaga
                    para 4.600 crianças.
                  </p>
                  <p>
                    E sabe os restaurantes comunitários? Hoje são mais de 30 mil refeições por dia
                    servidas por apenas 1 real.
                  </p>
                  <p>
                    Os programas sociais do GDF amparam mais de 1 milhão de pessoas. É por essa e
                    por outras que a aqui no DF a gente acredita que o melhor começa agora!
                  </p>
                  <p>Esse é o Governo do Distrito Federal!</p>
                </div>
              </div>

              <div v-if="campaign.id == 'pacote-institucional-reforco-2'">
                <p><b> Apresentador(a): </b></p>

                <div style="margin-left: 20px">
                  <p>Gente, olha só que novidade gostosa.</p>
                  <p>
                    O Restaurante Comunitário do Recanto das Emas já está servindo três refeições
                    diárias, em todos os dias da semana.
                  </p>
                  <p>O café da manhã custa cinquenta centavos.</p>
                  <p>O almoço continua com o mesmo preço de um real.</p>
                  <p>E o jantar, assim como o café da manhã sai, também, por cinquenta centavos.</p>
                  <p>É comida saudável, farta e barata na mesa do povo.</p>
                  <p>
                    E, em breve, o GDF vai estender esse benefício para todos os restaurantes
                    comunitários aqui do Distrito Federal.
                  </p>
                  <p>
                    Outra boa notícia é que uma recente pesquisa constatou que, nos últimos meses,
                    mais de 148 mil pessoas saíram da chamada “linha da pobreza”, aqui na capital.
                  </p>
                  <p>
                    Combater a fome e a pobreza deve ser prioridade para qualquer governo, certo?
                  </p>
                  <p>E é bom saber que o GDF está caminhando nesse sentido.</p>
                </div>
              </div>

              <div v-if="campaign.id == '2023-11-incentivos-fiscais'">
                <section class="mt-8">
                  <p class="mb-4">
                    <b>CONTEÚDO: INCENTIVOS</b>
                  </p>
                  <p class="ml-4">LOCUTOR: FALA DATA E HORÁRIO</p>

                  <div class="ml-4">
                    <p>
                      - Pessoal, a maior parte das pessoas passa por isso: às vezes, não dá para
                      acertar no dia uma parcela do IPTU, do IPVA, e em empresas também! Às vezes
                      existem momentos difíceis, como a pandemia, e algum imposto acaba ficando pra
                      trás. O GDF entende. E é por isso que ele lançou o Refis 2023. É uma
                      oportunidade para manter sua empresa com nome limpo ou tirar seu nome da
                      dívida ativa.
                    </p>
                    <p>
                      O Refis serve para o ICM, ICMS, ISS, ITBI, ITCD, TLP, IPVA, IPTU. Se algum
                      deles estiver atrasado, você pode ter desconto de até 99% nos juros ou dividir
                      em até 120 vezes.
                    </p>
                    <p>
                      Mas presta atenção: para garantir isso, você tem que ir no
                      receita.fazenda.df.gov.br ou ligar 156 até o dia 20 de novembro para
                      regularizar sua situação. E até o dia 30 para fazer a adesão. E nesse ano tem
                      uma novidade: uma entrada no ato de 10% do valor da dívida.
                    </p>
                    <p>É isso aí: para refazer seus sonhos, faça o Refis 2023. GDF.</p>
                  </div>
                </section>
                <section class="mt-8">
                  <p class="mb-4">
                    <b>CONTEÚDO: INCENTIVOS 2</b>
                  </p>
                  <p class="ml-4">LOCUTOR: FALA DATA E HORÁRIO</p>

                  <div class="ml-4">
                    <p>
                      - Pessoal, a maior parte das pessoas passa por isso: às vezes, não dá para
                      acertar no dia uma parcela do IPTU, do IPVA, e em empresas também! Às vezes
                      existem momentos difíceis, como a pandemia, e algum imposto acaba ficando pra
                      trás. O GDF entende. E é por isso que ele lançou o Refis 2023. É uma
                      oportunidade para manter sua empresa com nome limpo ou tirar seu nome da
                      dívida ativa.
                    </p>
                    <p>
                      O Refis serve para o ICM, ICMS, ISS, ITBI, ITCD, TLP, IPVA, IPTU. Se algum
                      deles estiver atrasado, você pode ter desconto de até 99% nos juros ou dividir
                      em até 120 vezes.
                    </p>
                    <p>
                      Mas presta atenção: para garantir isso, você tem que ir no
                      receita.fazenda.df.gov.br ou ligar 156 até o dia 29 de novembro para
                      regularizar sua situação. E até o dia 30 para fazer a adesão. E nesse ano tem
                      uma novidade: uma entrada no ato de 10% do valor da dívida.
                    </p>
                    <p>É isso aí: para refazer seus sonhos, faça o Refis 2023. GDF.</p>
                  </div>
                </section>
              </div>

              <div v-if="campaign.id == '12-2023-feliz-plano-novo-geap'">
                <section class="mt-8">
                  <p><b>FELIZ PLANO NOVO (T1)</b></p>

                  <div style="margin-left: 20px">
                    <p>
                      AGORA PRESTA ATENÇÃO QUE HOJE EU QUERO DAR A MELHOR DICA DO FIM DE ANO, PRA
                      VOCÊ ENTRAR NO ANO NOVO COM DINHEIRO NO BOLSO E SAÚDE GARANTIDA.
                    </p>
                    <p>A GEAP ESTÁ FAZENDO A PROMOÇÃO INDIQUE & GANHE.</p>
                    <p>
                      FUNCIONA ASSIM: VOCÊ, QUE JÁ TEM GEAP, INDICA AÍ UM PARENTE SEU QUE AINDA NÃO
                      TEM.
                    </p>
                    <p>
                      PODE SER PAI, MÃE, IRMÃOS, TIOS, AVÓ, AVÔ, MARIDO OU ESPOSA E FILHOS, CLARO,
                      PODE SER PRIMO, MESMO AQUELE PRIMO DISTANTE, DE TERCEIRO GRAU! PODE SER SOGRO
                      OU SOGRA.
                    </p>
                    <p>
                      VOCÊ QUE INDICOU GANHA UMA MENSALIDADE GRÁTIS. ENTÃO VAI SOBRAR ESSE
                      DINHEIRINHO AÍ NA SUA MÃO.
                    </p>
                    <p>O PARENTE QUE ENTRAR, ENTRA SEM CARÊNCIA.</p>
                    <p>PRESENTE EM DOBRO NO FIM DO ANO, HEIN!</p>
                    <p>
                      ACESSE GEAP.COM.BR, CONSULTE OS PREÇOS E CONDIÇÕES DOS PLANOS E INDIQUE &
                      GANHE COM A GEAP.
                    </p>
                    <p>SE PREFERIR, LIGUE GRÁTIS PARA 0800-728-8300. REPETINDO: 0800-728-8300.</p>
                    <p>MAS ATENÇÃO, A PROMOÇÃO VAI SÓ ATÉ O DIA 31 DESTE MÊS.</p>
                    <p>GEAP, O PLANO DA VIDA DO SERVIDOR PÚBLICO.</p>
                  </div>
                </section>
                <section class="mt-12">
                  <p><b>FELIZ PLANO NOVO | PRAÇA DF (T2)</b></p>

                  <div style="margin-left: 20px">
                    <p>
                      AGORA PRESTA ATENÇÃO QUE HOJE EU QUERO DAR A MELHOR DICA DO FIM DE ANO, PRA
                      VOCÊ ENTRAR NO ANO NOVO COM DINHEIRO NO BOLSO E SAÚDE GARANTIDA.
                    </p>
                    <p>A GEAP ESTÁ FAZENDO A PROMOÇÃO INDIQUE & GANHE.</p>
                    <p>
                      FUNCIONA ASSIM: VOCÊ, QUE JÁ TEM GEAP, INDICA AÍ UM PARENTE SEU QUE AINDA NÃO
                      TEM.
                    </p>
                    <p>
                      PODE SER PAI, MÃE, IRMÃOS, TIOS, AVÓ, AVÔ, MARIDO OU ESPOSA E FILHOS, CLARO,
                      PODE SER PRIMO, MESMO AQUELE PRIMO DISTANTE, DE TERCEIRO GRAU! PODE SER SOGRO
                      OU SOGRA.
                    </p>
                    <p>
                      VOCÊ QUE INDICOU GANHA UMA MENSALIDADE GRÁTIS. ENTÃO VAI SOBRAR ESSE
                      DINHEIRINHO AÍ NA SUA MÃO.
                    </p>
                    <p>O PARENTE QUE ENTRAR, ENTRA SEM CARÊNCIA.</p>
                    <p>PRESENTE EM DOBRO NO FIM DO ANO, HEIN!</p>
                    <p>
                      PRA VOCÊ DO DF, TEM MAIS UMA NOVIDADE: A PARTIR DE JANEIRO, O SÍRIO-LIBANÊS
                      BRASÍLIA PASSA A FAZER PARTE DA REDE CREDENCIADA. VOCÊ NÃO VAI PERDER ESSA!
                    </p>
                    <p>
                      ACESSE GEAP.COM.BR, CONSULTE OS PREÇOS E CONDIÇÕES DOS PLANOS E INDIQUE &
                      GANHE COM A GEAP.
                    </p>
                    <p>SE PREFERIR, LIGUE GRÁTIS PARA 0800-728-8300. REPETINDO: 0800-728-8300.</p>
                    <p>MAS ATENÇÃO, A PROMOÇÃO VAI SÓ ATÉ O DIA 31 DESTE MÊS.</p>
                    <p>GEAP, O PLANO DA VIDA DO SERVIDOR PÚBLICO.</p>
                  </div>
                </section>
              </div>

              <div v-if="campaign.id == '03-2024-doacao-de-sangue-2024'">
                <section class="mt-8">
                  <p><b>Apresentador:</b></p>

                  <div style="margin-left: 20px">
                    <p>
                      Você sabia que nesse exato momento pode ter alguém precisando do seu sangue?
                    </p>
                    <p>
                      Todos os dias, dezenas de pessoas precisam de uma transfusão, mas nem sempre
                      os estoques estão suficientemente cheios para atender a todo mundo. Que tal a
                      gente mudar essa triste realidade?
                    </p>
                    <p>
                      Se você tem entre 16 e 69 anos, pesa mais de 51 kg, não possui comorbidades,
                      está bem alimentado e hidratado, não passou por cirurgia ou não fez nenhum
                      procedimento estético recente, você pode ser um doador sangue bom.
                    </p>
                    <p>
                      Basta procurar o Hemocentro do Distrito Federal, que fica no Setor Médico
                      Hospitalar Norte, Quadra 3, Conjunto A, Bloco 3, ou se preferir, pode acessar
                      agora mesmo o site hemocentro.df.gov.br e tirar todas as suas dúvidas.
                    </p>
                    <p>
                      Ah, e todos os tipos sanguíneos são bem-vindos, viu? Procure hoje mesmo o
                      Hemocentro e torne-se um doador sangue bom.
                    </p>
                    <p>Governo do Distrito Federal.</p>
                  </div>
                </section>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row id="censure" class="text-center">
          <v-col cols="12">
            <h2 class="text-h4 primary--text text--darken-3 font-weight-light font-italic py-8">
              CENSURA
            </h2>
          </v-col>
        </v-row>
        <v-row class="pa-4">
          <v-col cols="12">
            <v-card v-if="campaign">
              <v-card-title>
                {{ campaign.name }}
              </v-card-title>
              <v-card-subtitle>{{ campaign.client }}</v-card-subtitle>
              <v-card-text class="">
                <v-row>
                  <v-col cols="12">
                    <strong>Qtde.:</strong>
                    <span>
                      {{ campaign.censuresData ? campaign.censuresData.length : 0 }}
                    </span>
                    <span class="mx-2"> | </span>
                    <strong>P.i.:</strong>
                    <span>
                      {{
                        campaign.piCamp
                          ? campaign.piCamp
                          : campaign.censuresData
                          ? campaign.censuresData[0].pi
                          : ""
                      }}
                    </span>
                    <span class="mx-2"> | </span>
                    <strong>Período:</strong>
                    <span>
                      {{
                        campaign.timeFrame
                          ? campaign.timeFrame
                          : campaign.censuresData
                          ? campaign.censuresData[0].periodo
                          : ""
                      }}
                    </span>
                    <span class="mx-2"> | </span>
                    <strong>Tipo/Duração:</strong>
                    <span>
                      {{
                        campaign.type
                          ? campaign.type
                          : campaign.censuresData
                          ? campaign.censuresData[0].tipo
                          : ""
                      }}/{{
                        campaign.format
                          ? campaign.format
                          : campaign.censuresData
                          ? campaign.censuresData[0].formato
                          : ""
                      }}
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="campaign.censuresData"
                  :search="search"
                  sort-by="idSecom"
                  show-expand
                  :expanded.sync="expanded"
                  :footer-props="{
                    'items-per-page-all-text': 'Todas',
                    'items-per-page-text': 'Censuras por página:',
                    'items-per-page-options': [15, 50, 100, -1],
                  }"
                  class="elevation-1"
                  item-key="idApp"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>CENSURAS</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-toolbar>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pb-8 pt-6 grey--text text--darken-1">
                      <strong>Programa:</strong> {{ item.programa }}
                      <span class="mx-2"> | </span>
                      <strong>Apresentador:</strong> {{ item.apresentador }}
                      <span class="mx-2"> | </span>
                      <strong>Hor. Início:</strong> {{ item.inicio }} <span class="mx-2"> | </span>
                      <strong>Hor. Fim:</strong>
                      {{ item.fim }}
                    </td>
                  </template>
                  <template v-slot:[`item.audio`]="{ item }">
                    <div v-if="item.audioData">
                      <v-icon small color="primary" @click="openAudioPlayer(item)">
                        mdi-play-circle
                      </v-icon>
                    </div>
                  </template>
                  <template v-slot:no-data>
                    <p class="mt-4">
                      Nenhuma censura encontrada! Os registros cadastrados aparecerão aqui.
                    </p>
                  </template>
                  <template v-slot:no-results>
                    <p class="mt-4">Nenhuma resultado encontrado para busca: {{ search }}</p>
                  </template>
                </v-data-table>
              </v-card-text>

              <v-card-actions class="pb-4 pr-4">
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click="expandAll()"
                  >{{ expanded.length ? "Fechar" : "Expandir" }} Todos</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div class="grey lighten-2 mt-12" id="footer">
        <v-container>
          <v-row id="censure" class="text-center my-0">
            <v-col cols="12">
              <!-- <p>footer</p> -->
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <!-- bottom audio player Dialog -->
    <v-bottom-sheet inset v-model="playerDialog" @click:outside="closeAudioPlayer()">
      <v-card tile>
        <v-progress-linear :value="100" class="my-0" height="3"></v-progress-linear>

        <v-container class="pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" sm="4">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ audioPlayerData.veiculo }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ audioPlayerData.apresentador }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="caption">
                      {{ audioPlayerData.programa }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6" class="mt-3 d-flex justify-center">
              <v-btn
                class="mr-2 mt-2 elevation-0 fixed-bottom"
                dark
                small
                color="primary"
                @click="playSprite()"
              >
                <v-icon dark small> mdi-play </v-icon>
                Tocar o trecho da Censura
              </v-btn>
              <audio
                v-if="audioPlayerData"
                :src="audioPlayerData.src"
                ref="audioPlayer"
                controls
                style="width: 100%"
              ></audio>
            </v-col>
            <v-col cols="12" sm="2" class="mb-8 mb-sm-2">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ audioPlayerData.veiculacao }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ audioPlayerData.time }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="caption">
                      {{ audioPlayerData.numInsercao }} |
                      {{ audioPlayerData.numTexto }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { db, onSnapshot, collection, query, where } from "@/firebase/firebase";

export default {
  name: "Campaign",

  components: {
    //
  },

  mounted() {
    if (this.$route.params.id) {
      // this.subscribeListerner();
      this.$store.dispatch("loadCampaignWithOnlyPublishedCensures", this.$route.params.id);
    }
  },

  destroyed() {
    this.unsubscribeListener();
    if (this.campaign) this.$store.dispatch("loadCampaignWithCensuresData", this.campaign.id);
  },

  data() {
    return {
      selectedDate: null,
      headers: [
        {
          text: "Data Veic.",
          sortable: true,
          value: "veiculacao",
        },
        {
          text: "Horário",
          sortable: true,
          value: "audioData.time",
        },
        {
          text: "Agência",
          sortable: true,
          value: "agencia",
        },
        {
          text: "iD Secom",
          sortable: true,
          value: "idSecom",
        },
        {
          text: "Pi Veículo",
          sortable: true,
          value: "piVeiculo",
        },
        {
          text: "Estado",
          sortable: true,
          value: "uf",
        },
        {
          text: "Município",
          sortable: true,
          value: "municipio",
        },
        {
          text: "Veículo",
          sortable: true,
          value: "veiculo",
        },
        {
          text: "Apresentador",
          sortable: true,
          value: "apresentador",
        },
        { text: "Áudio", value: "audio", sortable: false, align: "center" },
      ],
      search: "",
      expanded: [],
      censureList: null,
      internalLoading: null,
      unsubscribeListener: null,
      playerDialog: false,
      audioPlayerData: {},
      selectedAudio: null,
    };
  },

  computed: {
    campaign() {
      return this.$store.getters.getCampaign;
    },
    feedback() {
      return this.$store.getters.getFeedback;
    },
    user() {
      return this.$store.getters.getUser;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
  },

  watch: {
    campaign(v) {
      if (v) {
        this.subscribeListerner();
      }
    },
  },

  methods: {
    expandAll() {
      // console.log(this.expanded.length);
      if (!this.expanded.length) {
        this.expanded = this.campaign.censuresData;
      } else {
        this.expanded = [];
      }
    },
    subscribeListerner() {
      console.log("Subscribe Listener");
      this.internalLoading = true;
      const q = query(
        collection(db, "campaigns", this.$route.params.id, "censuresData"),
        where("status", ">=", 2)
      );
      this.unsubscribeListener = onSnapshot(q, (querySnapshot) => {
        const newCampaign = this.campaign;
        if (newCampaign) newCampaign.censuresData = [];
        querySnapshot.forEach((doc) => {
          const newCensure = {
            id: doc.id,
            ...doc.data(),
          };
          newCensure.veiculacao = newCensure.veiculacao.toDate().toLocaleDateString("pt-BR");
          newCampaign.censuresData.push(newCensure);
        });
        this.$store.dispatch("UpdateVuexCampaign", newCampaign);
      });
    },
    isOdd(num) {
      return num % 2;
    },
    playAudio2(id, src, start) {
      const player = document.getElementById(id);
      player.src = src;
      player.type = "audio/mpeg";
      if (player.paused == true) {
        if (start) player.currentTime = start;
        player.play();
      } else {
        player.pause();
      }
    },
    openAudioPlayer(item) {
      console.log("Open Player Dialog");
      this.selectedAudio = item.audioData;
      this.playerDialog = true;
      this.audioPlayerData.src = item.audioData.audioUrl;
      this.audioPlayerData.veiculo = item.veiculo;
      this.audioPlayerData.apresentador = item.apresentador;
      this.audioPlayerData.programa = item.programa;
      this.audioPlayerData.veiculacao = item.veiculacao;
      this.audioPlayerData.time = item.audioData.time;
      this.audioPlayerData.numTexto = item.numTexto;
      this.audioPlayerData.numInsercao = item.numInsercao;
      console.log(this.player);
    },
    closeAudioPlayer() {
      this.audioPlayerData = {};
      // this.audioPlayerData.src = null;
      this.$refs.audioPlayer.src = null;
      this.selectedAudio = null;
      console.log("closing...");
    },
    playSprite() {
      const player = this.$refs.audioPlayer;
      const initM = this.selectedAudio.cut.inicioMinutos;
      const initS = this.selectedAudio.cut.inicioSegundos;
      const finalM = this.selectedAudio.cut.fimMinutos;
      const finalS = this.selectedAudio.cut.fimSegundos;
      const initialTime = parseInt(initM) * 60 + parseInt(initS);
      let finalTime = parseInt(finalM) * 60 + parseInt(finalS);
      if (finalTime < 1) finalTime = player.duration;
      if (initialTime > finalTime)
        this.audioFeedback = "Tempo inicial do corte está maior que o tempo final...";
      if (player && initialTime <= finalTime) {
        player.currentTime = initialTime;
        this.spriteStatus = true;
        player.play();
        const playInterval = setInterval(() => {
          if (player.currentTime >= finalTime) {
            player.pause();
            clearInterval(playInterval);
            this.spriteStatus = false;
          }
        }, 1000);
      }
    },
    secondsToMinutes(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${("0" + minutes).slice(-2)}:${("0" + seconds).slice(-2)}`;
    },
  },
};
</script>

<style scoped>
.Campaign {
  padding: 0;
}
#footer {
  height: 400px;
}
.push-top-right {
  position: absolute;
  right: 21px;
  top: 21px;
}
.fixed-bottom {
  position: absolute;
  bottom: -3px;
}
</style>
